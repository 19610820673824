.drive-footer {
  @apply justify-between;
  @apply flex-wrap;
  @apply bg-black;
  @apply pb-18;
  @apply relative;
  &--preserve-bucket-height {
    @screen md {
      @apply mb-[192px];
    }
  }

  &__logo-wrapper {
    @apply text-center;
    @apply mb-12;
    @apply pt-8;
    @apply flex;
    @apply justify-center;
    @screen md {
      @apply hidden;
    }
  }
  &__left-section-wrapper {
    @apply py-8;
    @apply px-5;
    @apply overflow-hidden;
    max-width: 1440px;
    @apply m-auto;
    @screen lg {
      @apply px-10;
    }
    @screen md {
      @apply px-30px;
    }
  }
  &__left-section {
    @apply flex;
    @apply items-center;
    @apply justify-between;
    @screen lg {
      @apply mb-8;
    }
  }

  &__right-section {
    @screen md {
      @apply flex;
      @apply my-8;
      @apply mt-2;
      @apply mb-3;
      @apply border-b;
      @apply border-gray-500;
    }
  }
  &__copyright-desktop {
    @apply hidden;
    @screen md {
      @apply block;
    }
  }
  &__copyright-mobile {
    @apply py-6;
    @screen md {
      @apply hidden;
    }
  }

  &__bottom-menu-wrapper {
    @apply pb-0;
    @apply pt-2;
    @screen md {
      @apply pl-0;
      @apply pb-0;
      @apply pt-3;
    }
  }
}
