.d-ad {
  &--large {
    @apply relative;
    @apply w-screen;
    @apply -left-5;

    @screen md {
      position: unset;
      width: unset;
      left: unset;
    }
  }
}
