@use '/styles/mixin/fonts.module.scss' as *;

.drive-footer__back-to-top {
  @include font-subtext('mobile', 'medium');
  @apply z-60;
  @apply text-white;
  @apply bg-black;
  @apply items-center;
  @apply fixed;
  @apply z-20;
  @apply -translate-x-1/2;
  @apply shadow-sm;
  @apply rounded-r-none;
  @apply rounded-l-2xl;
  @apply w-53px;
  @apply h-12;
  padding: 0.625rem 0.781rem;
  bottom: 20%;
  left: 93%;
  &:hover {
    @apply outline-none;
  }
  @screen md {
    @include font-caption('desktop', 'bold');
    @apply left-auto;
    @apply text-black;
    @apply bg-white;
    @apply rounded-none;
    @apply rounded-tl-3xl;
    @apply rounded-bl-3xl;
    @apply absolute;
    @apply bottom-auto;
    @apply right-0;
    @apply top-0;
    @apply translate-x-0;
    @apply translate-y-1/2;
  }
  &__icon {
    @apply fill-current;
    @apply text-24px;
  }

  :global(img.drive-icon-BackToTopIcon) {
    @apply h-7;
    @apply w-7;
  }
}
