@use '/styles/mixin/fonts.module.scss' as *;

.drive-marketplace-usp-poster {
  @apply rounded-2xl;
  @apply border-2;
  @apply border-gray-200;
  @apply bg-white;
  @apply flex;
  @apply flex-col;
  @apply items-start;
  @apply w-full;
  @apply h-68;
  @screen md {
    @apply w-[344px];
  }
  @screen lg {
    @apply w-[360px];
  }

  &__header {
    @include font-subtext('desktop', 'large');
    @apply flex;
    @apply justify-center;
    @apply mt-6;
    @apply w-full;
    @apply mb-8;
    @screen md {
      @include font-subtext('mobile', 'large');
    }
    @screen lg {
      @include font-subtext('desktop', 'large');
    }
  }

  &__items-container {
    @apply flex;
    @apply flex-col;
    @apply mx-auto;
    @apply gap-4;

    [class^='d-usp-item_d-usp-item'] span {
      @include font-text('desktop', 'medium');
    }
  }
}
