@use '/styles/mixin/fonts.module.scss' as *;

.d-marketplace-usp-banner {
  background: linear-gradient(45deg, #070d4c, #164bad);
  @apply w-full;
  @apply h-12;
  @apply overflow-hidden;
  @screen md {
    @apply h-14;
  }
  &__container {
    @apply h-full;
    @apply flex;
    @apply justify-center;
    @apply items-center;
  }

  &__wrapper {
    @apply flex;
    @apply justify-between;
    @apply min-w-56;
    @apply h-full;
    @apply relative;
    @apply overflow-hidden;
    @apply relative;
    @screen l {
      @apply gap-12;
      @apply flex-row;
      @apply items-center;
    }
  }

  &__item {
    @apply absolute;
    @apply flex;
    @apply justify-center;
    @apply w-full;
    // start from bottom before animation
    transform: translateY(-125%);
    animation: scroll-items 12s ease-in-out infinite;
    @apply h-full;
    @apply justify-center;

    // tailwind classes not used keyframes requires directv value change in property
    @keyframes scroll-items {
      0% {
        transform: translateY(125%);
      }
      3% {
        transform: translateY(0);
      }
      24% {
        transform: translateY(0);
      }
      28% {
        transform: translateY(-125%);
      }
      100% {
        transform: translateY(-125%);
      }
    }
    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 3s;
    }
    &:nth-child(3) {
      animation-delay: 6s;
    }
    &:nth-child(4) {
      animation-delay: 9s;
    }
    @screen l {
      @apply translate-y-0;
      @apply w-auto;
      @apply relative;
      @apply animate-none;
      @apply top-0;
    }

    span {
      @apply text-white;
      @include font-text('mobile', 'small');
      @screen lg {
        @apply relative;
        @include font-text('desktop', 'small');
      }
    }
  }
}
