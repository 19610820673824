@use '/styles/mixin/fonts.module.scss' as *;
.drive-form-input {
  &__wrapper {
    @apply mt-4;
    :global(input[type='number']::-webkit-input-placeholder) {
      /* Chrome/Opera/Safari */
      @include font-subtext('mobile', 'large');
    }
  }

  &__text-area {
    @apply resize-y;
    @apply h-16;
  }
}
