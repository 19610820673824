.drive-form {
  &__success {
    @apply rounded;
    @apply p-3;
    @apply text-sm;
    @apply bg-green-200;
    @apply text-black;
  }
  &__failure {
    @apply rounded;
    @apply p-3;
    @apply text-sm;
    @apply bg-coral-light;
    @apply text-white;
  }
}
