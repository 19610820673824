//z-index of chat widget is 2147483639
$z-index-enquiry-form: 2147483641;
$z-index-menu: 2147483640;
$z-index-find-a-car-modal: $z-index-menu; //should be at least same as header menu
$z-index-athena-search-modal: $z-index-menu; //should be at least same as header menu
$z-index-contact-seller-bar: 2147483638;
$z-index-sub-navigation: 2147483637;
$z-index-header: 99;

$header-height-mobile: 64px;
$header-height-desktop: 80px;
