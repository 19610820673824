@use '/styles/mixin/fonts.module.scss' as *;
.drive-redbook-copyrights {
  @apply pb-5;
  @apply pt-8;
  @apply border-t;
  @apply border-gray-300;
  @screen xs {
    @apply pl-4;
  }
  @screen lg {
    @apply pl-0;
    @apply text-left;
    &--listing-not-available {
      @apply mr-75;
    }
  }

  &__link {
    @include font-subtext('mobile', 'large');

    @apply text-gray-400;
    @apply cursor-pointer;
    &:hover {
      @apply text-gray-400;
    }
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
}
