@use '/styles/mixin/fonts.module.scss' as *;

.drive-compare-bucket_card {
  @apply flex;
  @apply items-center;
  @apply h-[67px];

  @screen md {
    @apply flex-col;
    @apply justify-center;
    @apply mx-0;
    @apply w-[172px];
    @apply h-full;
  }

  &__remove-btn {
    @apply mt-[-52px];
    @apply mr-[-5px];
    @apply z-10;

    &:hover {
      @apply cursor-pointer;
    }

    @screen md {
      @apply absolute;
      @apply top-0;
      @apply mt-[40px];
      @apply ml-[-155px];
    }

    @screen lmd {
      @apply mt-[39px];
      @apply ml-[-155px];
    }
  }

  &__remove-btn-icon {
    @apply text-base;
    @apply fill-current;
    @apply border;
    @apply border-black;
    @apply rounded-full;
    @apply text-black;
    @apply bg-white;
  }

  &__variant-name {
    @screen xs {
      @apply w-[125px];
      @apply ml-4;
      @include font-subtext('mobile', 'medium');
    }

    @screen md {
      @apply w-auto;
      @apply mx-auto;
      @apply mt-1;
      @apply text-center;
    }

    @screen md {
      @apply w-[172px];
    }
  }

  &__image {
    @apply mt-2;
    @apply w-[112px];
    @apply h-[63px];

    @screen md {
      @apply w-[144px];
      @apply h-[81px];
    }

    @screen xl {
      @apply mx-auto;
    }

    img {
      @apply h-auto;
      @apply w-full;
    }
  }
}
