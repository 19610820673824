@use '/styles/mixin/fonts.module.scss' as *;

.drive-compare-bucket_container {
  @apply fixed;
  @apply top-[134px];
  @apply right-0;
  @apply flex flex-row;
  @apply items-center justify-end;
  @apply w-[315px];
  @apply max-h-[343px];
  @apply bg-transparent;
  @apply shadow-none;
  @apply z-[55];
  &::before {
    content: '';
    @apply h-full;
    @apply w-full;
    @apply fixed;
    @apply top-0;
    @apply left-0;
    @apply z-[10];
    @apply bg-transparent;
    transition: background;
    @apply duration-500;
    @apply ease-in-out;
    @apply pointer-events-none;
    @screen md {
      @apply hidden;
    }
  }
  @screen md {
    @apply top-auto;
    @apply bottom-0;
    @apply flex-col;
    @apply w-full-screen;
    @apply h-48;
    @apply shadow-inner;
    @apply pt-1;
  }
  @screen lmd {
    @apply bg-white;
  }
  &--show {
    @apply pointer-events-auto;
    &::before {
      content: '';
      @apply h-full;
      @apply w-full;
      @apply fixed;
      @apply top-0;
      @apply left-0;
      @apply z-[10];
      @apply bg-[rgba(0,0,0,0.5)];
      transition: background;
      @apply duration-500;
      @apply ease-in-out;
      @apply pointer-events-auto;
      @screen md {
        @apply hidden;
      }
    }
  }
  &--hide {
    @apply pointer-events-none;
    @screen sm {
      @apply pointer-events-auto;
    }
  }
}
.drive-compare-bucket_compare-btn {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply -rotate-90;
  @apply bg-white;
  @apply rounded-tl-lg;
  @apply rounded-tr-lg;
  @apply text-center;
  @apply mx-[-30px];
  @apply pointer-events-auto;
  @apply w-20;
  @apply h-7;
  @apply z-[11];
  @include font-caption('mobile', 'bold');
  @apply mt-0;
  transition: margin 0.25s ease-in-out;
  &--add-animation {
    @apply -mt-4;
  }
  &--shadowed {
    box-shadow: -2px -1px 4px rgba(0, 0, 0, 0.1);
  }
  @screen md {
    @apply hidden;
  }
}
.drive-compare-bucket_cards-container {
  @apply grid;
  @apply grid-cols-1;
  @apply bg-white;
  @apply pt-3;
  @apply px-3;
  @apply w-[259px];
  @apply h-[343px];
  @apply z-[11];
  @screen md {
    @apply grid-cols-4;
    @apply px-6;
    @apply pt-0;
  }
  @screen lmd {
    grid-template-columns: 197px 221px 196px 226px;
    @apply w-[880px];
    @apply mx-auto;
  }
  @screen l {
    grid-template-columns: 205px 237px 204px 234px;
  }
  &--show {
    transition: all 1s ease-in-out;
    @apply w-[291px];
    @apply h-[343px];
    @screen md {
      @apply w-full-screen;
    }
    @screen lmd {
      @apply w-[880px];
      @apply mx-auto;
    }
  }
  &--hide {
    transition: all 1s ease-in-out;
    @apply mr-[-259px];
    @apply h-[343px];
    @screen md {
      @apply w-full-screen;
      @apply mr-0;
      @apply h-48;
    }
    @screen lmd {
      @apply w-[880px];
      @apply mx-auto;
    }
    @screen l {
      @apply p-0;
    }
  }
}
.drive-compare-bucket_card-wrapper {
  @apply h-[75px];
  @screen md {
    @apply flex;
    @apply flex-row;
    @apply px-0;
    @apply justify-around;
    @apply self-center;
    @apply min-w-[180px];
    @apply w-full;
    @apply h-36;
  }
  @screen lmd {
    @apply w-full;
    @apply justify-start;
    @apply pl-[24px];
  }
  @screen l {
    @apply pl-[32px];
  }
  &--highted {
    @apply h-[83px];
    @apply flex;
    @apply items-center;
    @screen md {
      @apply h-[144px];
    }
  }
  &--bordered {
    @apply border-b;
    @apply border-black;
    @screen md {
      @apply border-b-0;
      @apply border-r;
    }
  }
}
