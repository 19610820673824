@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  @apply flex;
  @apply items-baseline;
  @apply text-black;
  @include font-text('desktop', 'small');

  &__icon {
    @apply translate-y-[10px];
    @apply mr-2;
  }
  &__text {
    @apply m-0;
  }
}
