@use '/styles/mixin/fonts.module.scss' as *;

.drive-nused-banner {
  @apply relative;
  @apply w-full;
  @apply h-[104px];
  @apply pt-3;
  @apply pb-4;
  @apply pl-6;
  @apply pr-3.5;
  background: linear-gradient(96.63deg, #070d4c 13.89%, #164bad 142.66%);
  @apply text-white;
  @screen sm {
    @apply h-40;
    @apply pb-7;
    @apply pl-8;
  }
  @screen lg {
    @apply pl-[42px];
    @apply pr-4.5;
  }
  @screen xl {
    @apply flex;
    @apply justify-center;
    @apply pt-4;
    @apply pb-6;
  }

  &__wrapper {
    @apply w-full;
    @screen xl {
      @apply w-[1100px];
    }
    @screen l {
      @apply w-[1270px];
    }

    &__title-link {
      @apply flex;
      @apply justify-between;

      &__nused-logo {
        @apply w-[83px];
        @apply h-4;
        @apply mt-1;
        @screen sm {
          @apply mt-4;
          @apply w-[114px];
          @apply h-6;
        }
        @screen lg {
          @apply mt-[28px];
        }
        @screen xl {
          @apply mt-5;
          @apply w-[134px];
          @apply h-[26px];
        }
      }

      a {
        @apply h-5;
        @screen sm {
          @apply h-6;
        }
      }

      &__external-link {
        @include font-subtext('mobile', 'medium');
        @apply absolute;
        @apply top-3;
        @apply right-3.5;
        @apply flex;
        @apply items-center;
        @apply gap-x-1.5;
        @apply cursor-pointer;
        @apply text-white;
        @screen sm {
          @apply gap-x-2;
          @apply h-6;
        }
        @screen lg {
          @apply right-4;
        }
        @screen xl {
          @apply top-4;
          @apply right-5;
          @include font-subtext('desktop', 'medium');
        }

        img {
          @apply w-3;
          @apply h-3;
          @screen sm {
            @apply w-5;
            @apply h-5;
          }
        }
      }
    }

    &__description-image {
      @apply flex;

      p {
        @apply mt-4;
        @apply mb-0;
        @apply mr-2.5;
        @apply w-full;
        @include font-text('mobile', 'small');
        @screen sm {
          @apply mr-0;
          @apply max-w-[284px];
          @include font-text('mobile', 'medium');
        }
        @screen lg {
          @apply max-w-[378px];
        }
        @screen xl {
          @include font-text('desktop', 'medium');
          @apply max-w-[396px];
        }
      }

      img {
        @apply hidden;
        @screen sm {
          @apply block;
          @apply bg-transparent;
          @apply w-[380px];
          @apply h-[100px];
          @apply ml-4;
          @apply -mt-5.5;
        }
        @media (min-width: 860px) {
          @apply ml-[100px];
        }
        @screen lmd {
          @apply ml-40;
        }
        @screen lg {
          @apply -mt-[38px];
          @apply ml-[75px];
          @apply w-[410px];
          @apply h-[108px];
        }
        @screen xl {
          @apply -mt-[46px];
          @apply ml-[100px];
          @apply w-[482px];
          @apply h-[127px];
        }
        @screen l {
          @apply ml-[220px];
        }
      }
    }
  }
}
