.drive-ad-spacing {
  @apply transition-all;
  &:not(:global(.layout-extra-ad)) {
    @apply w-full;
  }
  &__hr {
    border: none;
    @apply m-0;
  }
  // Give POS3 width 100% for Teads
  :global(.gam-inreads-pos3-section) {
    @apply w-full;
    @apply items-center;
  }
  //Give POS3 width 150% for Teads(1024px and above)
  :global(.teads-inread) {
    @screen lg {
      @apply w-570px;
    }
  }
  //make ad content stay in center
  :global(.gam-ad > div) {
    @apply table;
  }

  &__roofrack {
    @apply hidden;

    @screen lg {
      @apply block;
    }
  }

  &--no-data {
    @apply py-0 #{!important};
  }
}
