@use '/styles/mixin/fonts.module.scss' as *;

.bucket-comparison-cta {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply h-[80px];

  @screen md {
    @apply pb-4;
    @apply ml-[-1px];
    @apply h-full;
  }

  @screen l {
    @apply pb-4;
    @apply justify-end;
  }

  a {
    @apply bg-blue-dark;
    @apply text-center;
    @apply text-white;
    @include font-button();
    @apply h-12;
    @apply py-4;
    @apply rounded-lg;
    @apply w-[259px];

    @screen md {
      @apply w-[150px];
    }

    @screen lmd {
      @apply w-[174px];
    }

    @screen l {
      @apply block;
      @apply w-[188px];
      @apply m-0;
    }
  }
}
