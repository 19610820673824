.drive-disclaimers {
  @apply py-0;
  &__btn-wrapper {
    @apply flex;
    @apply flex-wrap;
  }

  &__btn-content {
    @apply leading-6;
    @apply text-sm;
    @screen md {
      @apply w-10/12;
    }
  }

  &__content {
    @apply leading-6;
    @apply text-sm;
    @apply font-medium;
    @apply text-white;
  }

  &__btn {
    @apply hidden;
    @apply text-right;
    @apply text-sm;
    @apply text-white;
    @apply font-semibold;
    &:focus {
      @apply outline-none;
    }
    @screen md {
      @apply block;
      @apply w-2/12;
    }
  }

  &__btn-mobile {
    @apply text-sm;
    @apply text-white;
    @apply font-semibold;
    @screen md {
      @apply hidden;
    }
    &:focus {
      @apply outline-none;
    }
  }

  &__btn-content-compact {
    @apply text-gray-500;
    @apply font-normal;
  }

  &__btn-content-full {
    @apply text-white;
    @apply font-medium;
  }
}
