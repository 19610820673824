@use '/styles/mixin/fonts.module.scss' as *;

.d-usp-item {
  @apply flex;
  @apply items-center;

  span {
    @apply ml-2;
    @include font-subtext('mobile', 'medium');
    @screen lg {
      @include font-subtext('desktop', 'medium');
    }
  }
}
