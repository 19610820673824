$white: #ffffff;
$gray-100: #f7f7f7;
$gray-200: #efefef;
$gray-300: #e3e3e3;
$gray-400: #a7a7a7;
$gray-500: #666666;
$gray-600: #3f3f3f;
$black: #000000;

$blue-faint: #d8eafe;
$blue-light: #89c1fc;
$blue-normal: #3b97fa;
$blue-dark: #2960c5;

$teal-faint: #cceded;
$teal-light: #66cac8;
$teal-normal: #00a6a4;
$teal-dark: #007b79;

$coral-light: #ff9e99;
$coral-normal: #ff5e55;
$coral-dark: #d73f3c;

$gray-1: #ebebeb;
$gray-2: #8a8a8a;
$gray-3: #f1f1f1;

$blue-1: #1f5ecf;
$light-bg: #f7f7f7;
$light-blue: #e7f7f7;
