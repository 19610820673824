.drive-footer__partners-logo {
  @apply flex;
  @apply flex-wrap;
  @apply -mx-2;
  @apply overflow-hidden;
  @screen md {
    @apply justify-end;
    @apply -mr-6;
    width: 39rem;
  }
  @screen xl {
    @apply flex-nowrap;
    margin-right: 1.688rem;
    @apply justify-end;
    width: 69.125rem;
  }

  &__wrapper {
    @apply my-3;
    @apply px-2;
    @apply w-1/2;
    @apply overflow-hidden;
    @apply text-center;
    @screen md {
      @apply w-1/3;
      @apply px-0;
    }
    @screen xl {
      @apply w-auto;
      @apply px-0;
    }
  }

  &__link {
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply mx-auto;
    @apply object-cover;
    @apply w-147px;
    @apply h-44px;
    @apply pl-1;
    &:odd {
      @apply pr-1;
    }
    @screen md {
      @apply pl-0;
      &:odd {
        @apply pr-0;
      }
    }
  }
  &__icon {
    @apply fill-current;
    @apply max-w-35;
    width: auto;
  }
  &__logo-mr-50px {
    @screen xl {
      margin-right: 3.13rem;
    }
  }
  &__logo-mr-32px {
    @screen xl {
      @apply mr-8;
    }
  }
  &__logo-mr-3px {
    @screen xl {
      margin-right: 0.188rem;
    }
  }
  &__logo-mr-neg-5px {
    @screen xl {
      margin-right: -0.313rem;
    }
  }
}
