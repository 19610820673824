.drive-dealer-licence {
  @apply pl-4;
  @apply mb-5;
  @apply font-primary;
  @apply text-10px;
  @apply font-normal;
  @apply text-gray-400;

  @screen lg {
    @apply pl-0;
    @apply text-left;
  }
  &__overlay-div {
    min-width: 70%;
    height: 2rem;
    position: absolute;
    opacity: 0;
    @screen md {
      height: 1.5rem;
      min-width: 30%;
    }
  }
}
