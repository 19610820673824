@use '../../styles/variables.scss' as *;

.roof-rack {
  &__ad-bg {
    @apply w-full;
    @apply flex;
    @apply justify-center;
    @apply bg-gray-100;
    @apply sticky;
    @apply md:static;
    @apply top-0;
    @apply z-40;
    @apply h-[70px];
    // with padding at top and bottom

    @screen sm {
      @apply h-[80px];
    }
    @screen md {
      @apply h-[120px];
    }
    @screen lg {
      @apply h-auto;
      @apply max-h-[280px];
    }
    &--mobile-persist-sticky {
      @screen md {
        @apply top-0;
      }
      &__under-header {
        top: $header-height-mobile;
      }
    }

    &--temp-sticky {
      @screen md {
        @apply sticky;
      }
    }

    &--sticky-roof-rack {
      @media (min-width: 1280px) {
        @apply sticky;
        @apply top-20;
      }
    }

    &--temp-sticky-under-header {
      @screen md {
        @apply top-16;
      }
      @screen xl {
        @apply top-20;
      }
    }

    &--temp-sticky-done {
      @screen md {
        @apply sticky;
        animation: slideOut 1s forwards;
        @keyframes slideOut {
          0% {
            @apply opacity-100;
            transform: translateY(0); // tailwind's translateY breaks animaition
          }
          99% {
            @apply opacity-20;
            transform: translateY(-120px);
          }
          100% {
            @apply opacity-100;
            @apply relative;
          }
        }
      }
    }

    &:global(.homepage-roofrack) {
      @apply flex;

      @screen lg {
        @apply hidden;
      }
    }
  }

  &__ad-bg__color {
    @apply bg-transparent;
  }

  &__ad-wrapper {
    @apply container;
    @apply z-40;
    @apply flex;
    @apply justify-center;
    @apply w-full;
    @apply m-auto;
    @apply relative;
  }
}
