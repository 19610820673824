@use '../styles/variables.scss' as *;

.body__container {
  &__templates {
    &--cfs__single {
      & + [class^='liveChat_drive-container'] {
        [class^='greeting_drive-greeting__wrapper'],
        [class^='openChat_drive__open-chat__tab-container'] {
          @apply mb-[70px];
          @screen lg {
            @apply mb-[auto];
          }
        }
      }
    }
  }
}

.drive-layout {
  &__footer-wrapper {
    @apply relative;
    @apply z-40;
  }
}
