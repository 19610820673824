.drive-social {
  &__wrapper {
    @apply flex;
  }
  &__icon {
    @apply w-full;
    @apply h-full;
    @apply object-contain;
    @apply fill-current;
  }

  &__background {
    @apply bg-blue-dark;
    &:hover {
      @apply bg-blue-normal;
    }
  }
  &__icon {
    @apply text-white;
  }
  &__link {
    @apply rounded-full;
    @apply object-contain;
    @apply mt-1;
    @apply mr-6;
    @apply p-2;
    @screen md {
      @apply mr-0;
      @apply ml-3;
    }
  }
}
