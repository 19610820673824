.drive-form-checkbox {
  &__label-page {
    @apply w-1/2;
    @screen lg {
      @apply w-1/4;
    }
  }
  &__separator {
    @apply w-full;
  }
  &__input-wrapper {
    @apply flex;
    @apply items-center;
  }

  &__tc-error {
    @apply text-coral-normal;
  }
}
