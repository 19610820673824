@use '/styles/1-settings/colors' as *;
@use '/styles/mixin/fonts.module.scss' as *;

.drive-subscribe {
  &__wrapper {
    @apply bg-teal-dark;
    @apply pt-10;
    @apply pb-5;
  }
  &__container {
    @apply container;
    @apply mx-auto;
    @apply flex;
    @apply flex-col;
    @apply pl-4;
    @apply pr-4;
    @screen md {
      @apply flex-row;
      @apply p-0;
    }
    @screen lg {
      @apply flex-row;
      @apply pl-0;
      @apply pr-0;
    }
  }

  &__mobile {
    @screen md {
      @apply hidden;
    }
  }

  &__mobile-btn {
    @apply w-full;
    @apply bg-white;
    &:last {
      @apply my-0;
    }
  }

  &__mobile-social {
    @apply justify-center;
    @apply text-white;
  }

  &__mobile-social-bg {
    @apply bg-white;
    @apply mt-10;
    &:hover {
      @apply bg-blue-normal;
    }
  }

  &__mobile-social-icon {
    @apply text-teal-dark;
  }
  &__mobile-social-warpper {
    @apply justify-center;
  }

  &__desktop {
    @apply hidden;
    @screen md {
      @apply flex;
    }
  }
}

.drive-subscribe-message {
  &__wrapper {
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    @apply w-full;
    @apply px-1;
  }
  &__heading {
    @apply text-21px;
    @apply font-primary;
    @apply text-white;
    @apply font-normal;
    @apply text-center;
    @apply px-8;
    @apply pb-1;
    @screen md {
      @apply text-2xl;
    }
  }
  &__content {
    @apply font-normal;
    @apply text-center;
    @apply text-white;
    @apply text-15px;
    @apply font-primary;
    @screen md {
      @apply text-14px;
    }
  }
  &__follow-bg {
    @apply bg-white;
    @apply mt-10;
    &:hover {
      @apply bg-blue-normal;
    }
  }
  &__follow-icon {
    @apply text-teal-dark;
  }
  &__follow-wrapper {
    @apply text-center;
    @apply justify-center;
  }
}

.drive-subscribe-content {
  &__wrapper {
    @apply bg-teal-dark;
    @apply pt-8;
    @apply pb-10;
  }
  &__container {
    @apply container;
    @apply flex;
    @apply flex-col;
    @apply px-5;
    @apply mx-auto;
    @screen xl {
      @apply pl-0;
      @apply pr-0;
      @apply mx-auto;
    }
    @screen md {
      @apply flex-row;
    }
  }
  &__form {
    @apply flex;
    @apply flex-wrap;
    @apply w-full;

    @screen md {
      @apply flex-col;
    }
    @screen lg {
      @apply flex-row;
    }
  }

  &__form-wrapper {
    @apply w-full;
    @screen md {
      @apply w-1/2;
    }
    @screen xl {
      @apply w-1/3;
    }
  }
  &__form-container {
    @apply items-center;
    @apply justify-center;
    @apply lg:mt-0;
  }
  &__form-heading {
    @apply justify-center;
    @include font-heading('desktop', 'h2');
    @apply pb-4;
    @apply text-white;
    @screen xs {
      @apply text-center;
    }
    @screen md {
      @apply text-left;
    }
  }
  &__form-msg {
    @apply justify-center;
    @apply text-white;
    @include font-heading('desktop', 'h3');
    @screen xs {
      @apply text-center;
      @apply pt-2;
    }
    @screen md {
      @apply text-left;
    }
  }
  &__form-msg-content {
    @apply justify-center;
    @apply pb-5;
    @apply font-normal;
    @apply text-white;
    @apply text-21px;
    @apply font-primary;
    @screen xs {
      @apply text-center;
    }
    @screen md {
      @apply text-2xl;
      @apply text-left;
    }
  }
  &__form-follow {
    @apply hidden;
    @apply mt-6;
    @screen xl {
      @apply flex;
    }
  }
  &__form-follow-bg {
    @apply bg-white;
    @apply mt-10;
    &:hover {
      @apply bg-teal-100;
    }
  }
  &__form-follow-icon {
    @apply text-teal-dark;
  }
  &__newsletter-wrapper {
    @apply w-full;

    @screen xl {
      @apply w-auto;
    }
  }
  &__newsletter-container {
    @apply relative;
    @apply justify-center;
  }
  &__newsletter-follow {
    @apply items-center;
    @screen xl {
      @apply hidden;
    }
  }
  &__newsletter-follow-bg {
    @apply bg-white;
    @apply mt-4;
    @apply mb-4;
    &:hover {
      @apply bg-blue-normal;
    }
  }
  &__newsletter-follow-icon {
    @apply text-teal-dark;
  }
  &__newsletter-follow-container {
    @apply justify-center;
    @screen md {
      @apply justify-start;
    }
    @apply pt-2;
    @apply pl-7;
  }

  &__newsletter-form {
    @apply flex;
    @apply flex-col;
    @apply flex-wrap;
    @apply items-center;
    @screen md {
      @apply w-11/12;
      @apply ml-10;
    }

    @screen xl {
      @apply max-w-[754px];
      @apply ml-10;
    }

    .errors {
      @apply w-full;
    }
  }

  &__enquiry-agree-label {
    @include font-caption('desktop', 'normal');
    @apply mb-6;

    @screen md {
      @apply self-end;
      @apply absolute;
      @apply pl-24;
      @apply w-2/5;
      @apply mt-24;
    }

    @screen xl {
      @apply pl-20;
    }
  }

  &__newsletter-btn {
    @apply w-full;
    @apply flex;
    @apply relative;
    @apply z-0;

    .error-btn {
      @apply text-white;
      @apply bg-coral-normal;
      border: unset;
    }
    @media (max-width: 1239px) {
      @apply justify-center;

      :global(.btn) {
        @apply text-teal-dark;
      }
    }

    @screen md {
      @apply justify-end;
      @apply absolute;
      @apply mt-56;
      :global(.btn) {
        width: 34%;
        @apply text-teal-dark;
      }
    }

    @screen xl {
      @apply mt-40;
    }
  }

  &__newsletter-form-fields {
    @apply flex;
    @apply flex-wrap;
    @apply justify-between;

    :global(.label) {
      @apply my-2;

      @screen md {
        width: 48%;
      }
      @apply text-white;
      a {
        @apply text-white;
        @apply underline;
        &:hover {
          @apply text-white;
        }
      }

      &:global(.email)q {
        @apply w-full;
      }
    }

    :global(.label) {
      &:global(.field-regular-width) {
        @screen md {
          width: 32%;
        }
      }
    }

    :global(.label) {
      &:global(.field-extra-width) {
        @screen md {
          width: 35%;
        }
      }
    }

    input {
      @apply text-base;
      @apply font-semibold;
      background-color: rgba(255, 255, 255, 0.25) !important;
    }

    :global(.checkboxes) {
      @apply w-full;
      @apply flex;
      @apply flex-col;
      @apply mb-6;
      @apply pt-4;
      z-index: 1;

      @media (max-width: 767px) {
        align-items: left;
      }

      @screen md {
        width: 60%;
        margin-bottom: 0.875rem;
        @apply relative;
        bottom: 0;
      }

      :global(.label) {
        @apply w-auto;

        input[type='checkbox'] {
          &:checked + :global(.checkbox) {
            @apply bg-white;

            &:after {
              border-color: $teal-dark;
              @apply bg-white;
              top: 0.3rem;
            }
          }
        }

        :global(.checkbox) {
          @apply border-white;
          background: transparent;
        }
      }
    }
  }
}
