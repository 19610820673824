.drive-footer-col {
  @apply mx-auto;
  @apply flex;
  @apply flex-col;
  @screen md {
    @apply flex-row;
  }

  &__one-wrapper {
    @apply w-full;
    @apply p-6;
    @apply pb-6;
    @screen md {
      @apply w-4/12;
      @apply pl-30px;
    }
    @screen lg {
      @apply w-5/12;
      @apply pl-10;
      @apply pr-0;
      @apply pt-8;
    }
    @screen xl {
      @apply pl-0;
    }
  }

  &__one-btn {
    @apply bg-white/10;
    @apply p-5;
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @screen md {
      @apply hidden;
      @apply bg-black;
    }
    &:focus {
      @apply outline-none;
    }
  }

  &__directory-close-icon {
    @apply w-4;
    @apply fill-current;
    @apply text-coral-normal;
    @apply text-xl;
  }
  &__directory-open-icon {
    @apply h-6;
    @apply w-6;
    @apply fill-current;
    @apply text-teal-normal;
    @apply text-3xl;
  }

  &__directory-wrapper {
    @apply w-full;
    @apply p-6;
    @apply bg-white/10;
    @screen md {
      @apply w-8/12;
      @apply pr-30px;
      @apply bg-black;
    }
    @screen lg {
      @apply pl-0;
      @apply w-7/12;
      @apply pr-10;
    }
    @screen xl {
      @apply pr-0;
    }
  }

  &__directory-close {
    @apply hidden;
    @screen md {
      @apply flex;
    }
  }

  &__two-wrapper {
    @apply w-full;
    @screen lg {
      @apply w-4/12;
    }
  }

  &__three-wrapper {
    @apply w-full;
    @apply pt-8;
    @screen md {
      @apply pt-0;
    }
    @screen lg {
      @apply w-4/12;
    }
  }

  &__four-wrapper {
    @apply w-full;
    @apply pt-10;
    @screen md {
      @apply pt-0;
    }
    @screen lg {
      @apply w-4/12;
    }
  }
}

.drive-footer-col__four {
  &__container {
    @apply flex;
    @apply flex-wrap;
    @screen md {
      @apply flex-col;
    }
  }

  &__item {
    @apply w-1/2;
    @apply flex;
    @apply items-center;
    @apply pr-2;
    @apply mb-6;
    &:last {
      @apply mt-2;
    }
    @screen md {
      @apply w-full;
      @apply mb-3;
      &:last {
        @apply mt-6;
      }
    }
  }
}

.drive-footer-col__one {
  &__container {
    @apply mb-8;
    &:last {
      @apply mb-0;
    }
  }
  &__item {
    @apply text-white;
    @apply text-xl;
    @apply font-normal;
    @apply capitalize;
    @apply leading-6;
    @screen md {
      @apply text-lg;
    }
  }
}

.drive-footer-col__three {
  &__container {
    @apply flex;
    @apply flex-wrap;
    @screen md {
      @apply flex-col;
    }
  }
  &__item {
    @apply w-1/2;
    @apply flex;
    @apply items-center;
    @apply pr-2;
    @apply mb-6;
    &:last {
      @apply mt-2;
    }
    @screen md {
      @apply mb-3;
      @apply w-full;
      &:last {
        @apply mt-6;
      }
    }
  }
}

.drive-footer-col__two {
  &__container {
    @apply flex;
    @apply flex-wrap;
    @screen md {
      @apply flex-col;
    }
  }
  &__item {
    @apply w-1/2;
    @apply flex;
    @apply items-center;
    @apply pr-2;
    @apply mb-8;
    @screen md {
      @apply w-full;
      @apply mb-6;
      &:last {
        @apply mb-0;
      }
    }
  }

  &__icon {
    @apply fill-current;
    @apply text-white;
    @apply mr-4;
  }

  &__link {
    @apply block;
    @apply w-full;
  }
}
